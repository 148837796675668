import { extendTheme, ThemeConfig } from '@chakra-ui/react';
import { components } from './components';
import { colors } from './colors';
import { styles } from './styles';

export { globalStyles } from './styles';

const config: ThemeConfig = {
  useSystemColorMode: true,
};

export const theme = extendTheme({
  config,
  colors,
  styles,
  components,
});
