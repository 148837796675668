import { Icon } from '@chakra-ui/icon';
import { Stack, Box, Flex, HStack, Text } from '@chakra-ui/layout';
import { FaInstagram, FaTiktok, FaTwitter } from 'react-icons/fa';

import { Logo } from '../components/Logo';
import { Page } from '../components/Page';

export const LandingScreen: React.FC = () => {
  return (
    <Page title="Welcome to Thread" withGradient={true}>
      <Flex
        p={{ base: 20, lg: 40 }}
        overflowY="auto"
        height="100vh"
        width="100vw"
        justifyContent="space-between"
        flexDir="column"
      >
        <Stack
          alignItems="center"
          justifyContent="space-between"
          direction={{ base: 'column', md: 'row' }}
          spacing={20}
        >
          <Logo height="100px" width="100px" />
          <HStack spacing={10}>
            <a href="https://instagram.com/threadconvos" target="_blank">
              <Icon as={FaInstagram} fontSize={30} color="#232323" />
            </a>
            <a href="https://tiktok.com/@threadconvos" target="_blank">
              <Icon as={FaTiktok} fontSize={26} color="#232323" />
            </a>
            <a href="https://twitter.com/threadconvos" target="_blank">
              <Icon as={FaTwitter} fontSize={30} color="#232323" />
            </a>
          </HStack>
        </Stack>
        <HStack
          alignItems="flex-end"
          paddingTop={400}
          flexDir={{ base: 'column', md: 'row' }}
        >
          <Text
            maxWidth={540}
            marginRight={{ base: 0, md: 20, xl: 100 }}
            fontSize={{ base: 30, md: 40, xl: 50 }}
            fontWeight="bold"
            color="#232323"
          >
            For those who desire nuanced conversations around complex topics.
          </Text>
          <Box
            display={{ base: 'none', md: 'block' }}
            flex={1}
            top={-18}
            position="relative"
            borderBottom="5px solid #232323"
          />
        </HStack>
      </Flex>
    </Page>
  );
};
