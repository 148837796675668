import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Gradient } from '../Gradient';

interface IPageProps {
  title?: string;
  withGradient?: boolean;
  withHeader?: boolean;
}

export const Page: React.FC<IPageProps> = ({
  title,
  withGradient = false,
  children,
}) => (
  <div style={{ height: '100vh', width: '100vw' }}>
    <Helmet>
      <title>{title ? `${title} • ` : ''}Thread</title>
    </Helmet>
    {withGradient ? (
      <Gradient height="100vh" width="100vw">
        {children}
      </Gradient>
    ) : (
      children
    )}
  </div>
);
