import { Box } from '@chakra-ui/layout';
import { ChakraComponent } from '@chakra-ui/system';

export const Logo: ChakraComponent<'img', any> = ({
  width = 50,
  height = 50,
  color = '#232323',
  ...rest
}) => (
  <Box width={width} height={height} {...rest}>
    <svg
      width="100%"
      height="100%"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 258.77 260"
    >
      <g id="Thread_Main" data-name="Thread Main">
        <path
          fill={color}
          d="M129.39,8A121.46,121.46,0,0,1,233.31,192.23l-1.7,2.8.76,3.18,12.57,52.37-66.69-8.86-2.06-.28-1.93.77A121.43,121.43,0,0,1,43.52,43.52,120.66,120.66,0,0,1,129.39,8m0-8a129.39,129.39,0,1,0,47.82,249.61L255.38,260l-15.27-63.65A129.41,129.41,0,0,0,129.39,0Z"
        />
        <path fill={color} d="M63,116.43v33H55.6v-33H44.82v-6.68H74v6.68Z" />
        <path
          fill={color}
          d="M96.63,149.41V133c0-4.1-2-6.07-5.28-6.07a6.9,6.9,0,0,0-7,7v15.56H77.58V109.75h6.74v14.44a12.34,12.34,0,0,1,8.88-3.71c6.12,0,10.06,3.48,10.06,9.94v19Z"
        />
        <path
          fill={color}
          d="M115.78,121.32v4c2.14-4.44,7-4.61,8.38-4.61h.56v6.91c-1.41,0-6.8,0-8.43,5.17a14.06,14.06,0,0,0-.51,4.61v12h-6.51V121.32Z"
        />
        <path
          fill={color}
          d="M133.51,136.71a7.61,7.61,0,0,0,7.92,7,8.09,8.09,0,0,0,7.36-3.88l5.73,3.32a16.4,16.4,0,0,1-13.71,7.13c-8.37,0-14.44-5.73-14.44-15.05,0-10.06,6.8-14.72,14.11-14.72,10.73,0,13.82,8.59,13.82,16.23Zm13.37-4.94c-.11-1.4-1-5.9-6.29-5.9-5.56,0-6.63,4.78-6.86,5.9Z"
        />
        <path
          fill={color}
          d="M175.71,149.41l-.17-2.19a13.69,13.69,0,0,1-8.26,2.86c-6.4,0-10.22-3.87-10.22-8.59,0-6.12,4.66-8.54,10-9.5a64.05,64.05,0,0,0,8.65-2.07c0-2.14-.62-4.22-4.83-4.22-5.12,0-5.68,2.76-5.68,4.22l-6.85-.4c.11-1.85.5-9,12.3-9,8.26,0,11.8,3,11.8,8v16.4a6.52,6.52,0,0,0,1.24,4.55Zm0-14a17.57,17.57,0,0,1-5.34,1.57c-3.09.45-6.18,1.07-6.18,3.88,0,1.85,1.24,3.43,4.61,3.43,3.09,0,6.91-1.58,6.91-5.11Z"
        />
        <path
          fill={color}
          d="M207.27,149.41v-2.7a9.48,9.48,0,0,1-7.81,3.54c-5.9,0-12.47-4.1-12.47-14.32,0-10.06,6.12-15.45,12.75-15.45,2.31,0,5.68.84,7.53,3.14V109.75H214v39.66Zm-6.57-5.28c3.65,0,6.51-3,6.51-8.26,0-4.27-1.23-9.16-6.46-9.16-4.21,0-6.74,3.77-6.74,8.49C194,139.35,195.75,144.13,200.7,144.13Z"
        />
      </g>
      {/* <path
        d="M25.0002 3.062C28.886 3.06298 32.7018 4.09162 36.057 6.04261C39.4122 7.99361 42.1863 10.7969 44.0953 14.1655C46.0044 17.5342 46.9797 21.3472 46.9216 25.2143C46.8635 29.0814 45.7741 32.8637 43.7647 36.174L43.1076 37.25L43.403 38.474L45.308 46.378L34.6457 44.966L33.8419 44.862L33.0964 45.158C30.0961 46.3453 26.8695 46.8584 23.6469 46.6606C20.4244 46.4629 17.2855 45.5591 14.4545 44.014C11.6236 42.4689 9.17047 40.3205 7.2705 37.7223C5.37053 35.1242 4.07059 32.1404 3.46358 28.9843C2.85657 25.8282 2.95746 22.5775 3.75906 19.4647C4.56065 16.3519 6.04315 13.4537 8.10063 10.9772C10.1581 8.50074 12.7398 6.50715 15.6611 5.13887C18.5825 3.77059 21.7715 3.06141 25.0002 3.062V3.062ZM25.0002 6.0765e-08C21.3181 -0.000256033 17.6815 0.808973 14.3502 2.36985C11.0188 3.93074 8.07504 6.20473 5.72917 9.02933C3.3833 11.8539 1.69328 15.1594 0.77987 18.7095C-0.133535 22.2596 -0.247767 25.9667 0.445338 29.5658C1.13844 33.1649 2.62178 36.5673 4.78932 39.5296C6.95687 42.492 9.75512 44.9413 12.9841 46.7024C16.2131 48.4635 19.793 49.493 23.4681 49.7173C27.1433 49.9417 30.8228 49.3552 34.2438 48L49.3491 50L46.3992 37.76C48.6918 33.9852 49.9352 29.6717 50.0021 25.2613C50.0689 20.8508 48.9567 16.502 46.7795 12.6601C44.6022 8.81815 41.4381 5.62121 37.6112 3.39666C33.7842 1.1721 29.432 -0.000131782 25.0002 6.0765e-08V6.0765e-08Z"
        fill={color}
      />
      <path
        d="M20.4686 19.5V32.978H17.4222V19.5H13.0013V16.768H24.9738V19.5H20.4686Z"
        fill={color}
      />
      <path
        d="M34.2677 32.978V26.25C34.2677 24.574 33.4378 23.772 32.0995 23.772C31.7214 23.7659 31.3458 23.8351 30.9949 23.9757C30.6441 24.1163 30.3251 24.3254 30.0567 24.5906C29.7883 24.8559 29.576 25.1719 29.4323 25.5201C29.2886 25.8683 29.2164 26.2416 29.2199 26.618V32.978H26.4468V16.778H29.2199V22.68C29.6947 22.1974 30.2619 21.8145 30.8881 21.5541C31.5142 21.2937 32.1865 21.161 32.8651 21.164C35.379 21.164 36.9926 22.588 36.9926 25.228V32.988L34.2677 32.978Z"
        fill={color}
      /> */}
    </svg>
  </Box>
);
