import { Route, BrowserRouter, Switch } from 'react-router-dom';

import * as screens from '../../screens';

export const Router: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" component={screens.LandingScreen} />
    </Switch>
  </BrowserRouter>
);
