import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import 'focus-visible/dist/focus-visible';

import { Global } from '@emotion/react';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';

import { globalStyles, theme } from './config';
import { Router } from './components/Router';

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <ChakraProvider theme={theme} resetCSS>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <Global styles={globalStyles} />
        <Router />
      </ChakraProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
